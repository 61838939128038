import React, { useEffect, useState } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  WixDesignSystemProvider,
  Page,
  Loader,
  Layout,
  Cell,
  Notification,
  ComposerHeader,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [showSaved, setShowSaved] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [config, setConfig] = useState<string | null>(null);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/social-box/_functions", // Replace with your API base URL
      applicationID: "e23c8679-12e6-4500-bbb7-51aa3793b18b", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getConfig();
  }, []);

  useEffect(() => {
    // Add event listener for messages from iframe
    window.addEventListener("message", handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event: MessageEvent): void => {
    // Ensure the message is from the expected origin (replace 'http://example.com' with your iframe's origin)
    const message = event.data;

    // Check if the message type is 'config'
    if (message.type === "config") {
      // Set message.config to the state
      setConfig(message.config);
    }
  };

  const CONFIG_BASE_URL =
    "https://certifiedcode.wixsite.com/social-box/_functions/config";

  useEffect(() => {
    try {
      // Replace 'http://example.com/your-iframe' with the actual URL of your iframe
      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.contentWindow?.postMessage(
          { type: "preview", socialboxConfig: config },
          "*"
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [config]);

  function getConfig() {
    fetch(CONFIG_BASE_URL, {
      method: "GET",
      headers: {
        Authorization: instance!,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
      })
      .catch((error) => {});
  }

  function saveConfig() {
    fetch(`${CONFIG_BASE_URL}`, {
      method: "POST",
      headers: {
        Authorization: instance!,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(config),
    })
      .then((response) => response.json())
      .then((data) => {
        setShowSaved(true);
      })
      .catch((error) => {});
  }

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification
        show={showSaved}
        onClose={() => {
          setShowSaved(false);
        }}
        autoHideTimeout={3000}
      >
        <Notification.TextLabel>
          Your changes have been published
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Layout gap={0}>
        <Cell>
          <ComposerHeader
          //  backButtonValue="Back"
          >
            <ComposerHeader.MainActions>
              {/* <Button disabled={
                !config
              } skin="inverted">Preview</Button> */}
              <Button 
              onClick={() => {
                saveConfig();
              }}
              disabled={!config || !isUpgraded}>Publish</Button>
              <Button
                suffixIcon={<Icons.PremiumFilled />}
                skin={isUpgraded ? "premium-light" : "premium"}
                as="a"
                href={basePlatform.getUpgradeUrl()}
                target="_blank"
              >
                {isUpgraded ? "Manage Plan" : instanceData?.instance?.freeTrialAvailable ? "Start a free trial":"Upgrade to set live"}
              </Button>
            </ComposerHeader.MainActions>
          </ComposerHeader>
        </Cell>
        <Cell>
          <Box direction="vertical">
            <Box gap="0" height="600px">
              <Box backgroundColor="D70" width="100%">
                <Box width="100%" padding={4}>
                  <iframe
                    src="https://social-bar.certifiedcode.io/"
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                  ></iframe>
                </Box>
              </Box>
            </Box>
          </Box>
        </Cell>
      </Layout>
    </WixDesignSystemProvider>
  );
}

export default App;
